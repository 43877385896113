import { Container, Row} from 'react-bootstrap'
import { useGetLocsQuery, useGetOthersQuery } from '../echron/clientapi'
import List_card from './List_card'
import '../assets/css/Location.css'
function List(props) {
	const page = props.page
	const loc_id = props.loc_id
	const cat_id = props.cat_id
	const pag_id = props.pag_id


  const locQr =  useGetLocsQuery({loc_id: loc_id, cat_id: cat_id, page: page })
	const locQrFl =  useGetOthersQuery({type: 'location', chain: '2', parent_id: 'null'});
	let locsRes = (pag_id=='5')? locQrFl : locQr;
	if(locsRes.isLoading) return <div>Loading...</div>
	if(locsRes.isError) return <h1>An Error occured {locsRes.error.error}</h1>
	if(locsRes.isSuccess) {
    let echronMap = (page==='location')? locsRes.data.location : locsRes.data.category
		return (
			<section className="Echron-Category">
				<Container>
					<h2>Properties in {props.name}</h2>
					<p>Explore Prime Properties Based On Your Recommendation</p>
					<Row className="py-3">
						{
							  echronMap.map((post)=>
								<List_card flex="3" key={post.id} name={post.name} image={post.image} url={post.url} className="child1" />
							)
						}
					</Row>
				</Container>
			</section>
		)
	}
}
export default List