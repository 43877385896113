import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import Property_card from "./Property_card";
import Loader from "./Loader";
import { useGetPostsQuery } from "../echron/clientapi";
import g1 from "../assets/img/g1.jpg";

import "../assets/css/Property_grid.css";

function Property_grid(props) {



  var cat_id = props.cat_id, loc_id = props.loc_id, dev_id = props.dev_id, pag_id = props.pag_id, order = props.order
  const postsRes = useGetPostsQuery({cat_id: cat_id, loc_id: loc_id, dev_id: dev_id, pag_id: pag_id, order: order});
  

  const cardcountLimit = props.limitcard;

  const [cardsData, setCardsData] = useState([]);
  
  useEffect(() => {

    if (postsRes.isSuccess && postsRes.data && postsRes.data.post) {
      let initialCards;

      if (cardcountLimit == 20) {
        initialCards = postsRes.data.post.slice(0, 20);
      } else {
        initialCards = postsRes.data.post;
      }
  
      // Update the state with initialCards
      setCardsData(initialCards);
    }
  }, [cardcountLimit, postsRes.isSuccess, postsRes.data]);
  
  

  
  
  useEffect(() => {
    const scrollHandler = () => {
      const boxes = document.querySelectorAll(".hiddencard");
      const triggerBottom = (window.innerHeight / 4)*4 ;
      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        if (boxTop < triggerBottom) {
          box.classList.add("active");
        } else {
          box.classList.remove("active");
        }
      });
    };

    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);


  if (postsRes.isLoading) return <Loader mtop="100px" />
  if (postsRes.isError) return <h1>An Error occured {postsRes.error.error}</h1>;
  if (postsRes.isSuccess) {

    var heading

    if(props.cat_id) {
      heading = (postsRes.data.catdetails)? postsRes.data.catdetails.name : ''
    }
    else if(props.loc_id) {
      heading = (postsRes.data.locdetails)? postsRes.data.locdetails.name : ''
    }
    else if(props.dev_id) {
      heading = (postsRes.data.devDetails)? postsRes.data.devDetails.name : ''
    }
    else if(props.pag_id) {
      
      switch(props.pag_id) {
        case 1 :
          heading = 'RESIDENTIAL COLLECTIONS'
        break
        case 2 :
          heading = 'COMMERCIAL COLLECTIONS'
        break
        case 3 :
          heading = 'SALE COLLECTIONS'
        break
        case 4 :
          heading = 'RENT COLLECTIONS'
        break
        default : heading = ''
      }
    }
    else {
      heading = 'TOP MOST COLLECTIONS'
    }




    



    return (
      <section className="Echron-Samplework">
        <div className="img">
            <img src={g1} alt="" />
            <h2>{heading}</h2>
            <p>Variety of Properties for Variety of Choices</p>
          </div>
        <Container>
          <Row className="echron-cards">
            {cardsData.map((post) => {
              if (post.location.length) {
                var location = (
                  <p>
                     <i className="fa-solid fa-location-dot"></i> &nbsp;
                    {post.location.map((loc, i) => {
                      const locname =
                        i !== post.location.length - 1 ? '' /* loc + ", " */ : loc;
                      return <span key={i}>{locname}</span>;
                    })}
                  </p>
                );
              }
              if (post.categroy.length) {
                var categroy = (
                  <h6>
                    {post.categroy.map((cat, i) => {
                      const catname =
                        i !== post.categroy.length - 1 ? cat + ", " : cat;
                      return <span key={i}>{catname}</span>;
                    })}
                  </h6>
                );
              }
              return (
                
                <Col key={post.id} lg="3" className="mb-4 hiddencard"  >
                  <Property_card
                    gallery={post.gallery}
                    name={post.name}
                    n_name={post.n_name}
                    categroy={categroy}
                    categroy_name={post.categroy_name}
                    location={location}
                    bedroom={post.bedroom}
                    area={post.area}
                    thumb={post.thumb}
                    amount={post.amount}
                    max_amount={post.max_amount}
                    rera={post.rera}
                    p_for={post.p_for}
                    p_type={post.p_type}
                    url={post.url}
                  />
                </Col>
              );
            })}
          </Row>
          {( cardsData.length == 20) && (
          <div className="text-center mt-4 view-morebutton">
            <a href="/residential-properties">View All</a>
          </div>
        )}
        </Container>
      </section>
    );
  }
}
export default Property_grid;
