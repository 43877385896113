import { Container } from "react-bootstrap";
import { useGetPostsQuery } from "../echron/clientapi";
import "../assets/css/Property_slider.css";
import Property_card from "./Property_card";
import Loader from "./Loader";
import { selectSlick } from "../echron/slickSlice";
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import "../assets/css/SliderSlick.css";

function Property_slider(props) {
  var cat_id = props.cat_id, loc_id = props.loc_id, feature = props.feature;
  const postsRes = useGetPostsQuery({ cat_id: cat_id, loc_id: loc_id, feature: feature });

  let location_result;
  if(props.locationss.length === 3){
    location_result = props.locationss[1];
  }else if(props.locationss.length === 2){
    location_result = props.locationss[0];
  }else{
    location_result = 0;
  }

  let category_result;
  if(props.categoryss.length === 2){
    category_result = props.categoryss[1];
  }else{
    category_result = 0;
  }
  
  const slick = useSelector(selectSlick);

  if (postsRes.isLoading) return <Loader mtop="100px" />
  if (postsRes.isError) return <h1>An Error occured {postsRes.error.error}</h1>;
  if (postsRes.isSuccess) {
    var heading;

    if (props.cat_id) {
      heading = <h2>{postsRes.data.catdetails.name}</h2>;
    } else if (props.loc_id) {
      heading = <h2>{postsRes.data.locdetails.name}</h2>;
    } else {
      heading = <h2>TRENDING PROJECTS</h2>;
    }

    const slide = ()=>{
      if(location_result.length > 0 && category_result.length > 0){
        return <Slider {...slick.m1}>
            {
           postsRes.data.post.filter(post => {
            if (post.id.toString().length < 1) {
                return post.location[1] == location_result;
            } else {
                return post.location[1] == location_result && category_result == post.categroy_name;
            }
        }).map((post) => {
              if (post.location.length) {
                var location = (
                  <p>
                    <i className="fa fa-map-marker"></i> &nbsp;
                    {post.location.map((loc, i) => {
                      const locname =
                        i !== post.location.length - 1
                          ? "" 
                          : loc;
                      return <span key={i}>{locname}</span>;
                    })}
                  </p>
                );
              }
              if (post.categroy.length) {
                var categroy = (
                  <h6>
                    {post.categroy.map((cat, i) => {
                      const catname =
                        i !== post.categroy.length - 1 ? cat + ", " : cat;
                      return <span key={i}>{catname}</span>;
                    })}
                  </h6>
                );
              }
              return (
                <div className="p-2" key={post.id}>
                  <Property_card
                   gallery={post.gallery}
                   name={post.name}
                   n_name={post.n_name}
                   categroy={categroy}
                   categroy_name={post.categroy_name}
                   location={location}
                   bedroom={post.bedroom}
                   area={post.area}
                   thumb={post.thumb}
                   amount={post.amount}
                   max_amount={post.max_amount}
                   rera={post.rera}
                   p_for={post.p_for}
                   p_type={post.p_type}
                   url={post.url}
                   n_description={post.n_description}
                   developer={post.developer}
                  />
                </div>
              );
            })}
          </Slider>
      }else{
        return <Slider {...slick.m1}>
        {
        postsRes.data.post.map((post) => {
          
          if (post.location.length) {
            var location = (
              <p>
                <i className="fa fa-map-marker"></i> &nbsp;
                {post.location.map((loc, i) => {
                  const locname =
                    i !== post.location.length - 1
                      ? ""
                      : loc;
                  return <span key={i}>{locname}</span>;
                })}
              </p>
            );
          }
          if (post.categroy.length) {
            var categroy = (
              <h6>
                {post.categroy.map((cat, i) => {
                  const catname =
                    i !== post.categroy.length - 1 ? cat + ", " : cat;
                  return <span key={i}>{catname}</span>;
                })}
              </h6>
            );
          }
          return (
            <div className="p-2" key={post.id}>
              <Property_card
               gallery={post.gallery}
               name={post.name}
               n_name={post.n_name}
               categroy={categroy}
               categroy_name={post.categroy_name}
               location={location}
               bedroom={post.bedroom}
               area={post.area}
               thumb={post.thumb}
               amount={post.amount}
               max_amount={post.max_amount}
               rera={post.rera}
               p_for={post.p_for}
               p_type={post.p_type}
               url={post.url}
               n_description={post.n_description}
               developer={post.developer}
              />
            </div>
          );
        })}
      </Slider>
      }
    }

    return (
      <section className="echron-cards topslider">
        <Container>
          <div className="text">
            {heading}
            <p>Get the best real-estate deals in your city</p>
          </div>
          {slide()}
        </Container>
      </section>
    );
  }
}
export default Property_slider;
