import {useState} from 'react'
import { Banner1 } from "./Image";
import "../assets/css/Banner.css";
import { useGetOthersQuery } from "../echron/clientapi";
import { useNavigate } from "react-router-dom";
import Loader from './Loader';
import { NavLink } from 'react-bootstrap';
function Banner(props) {

  //state
  const [echronSt, setEchronSt] = useState({ cityId: 0, cityUrl: '', localityId: 0, localityUrl: '' });

  //city
  const citiesRes = useGetOthersQuery({type: 'location', chain: '2', parent_id: 'null'});

  //locality
  const localityRes = useGetOthersQuery({type: 'location', chain: 'null', parent_id: echronSt.cityId});
  var locality;
  if(localityRes.isSuccess) {
    locality = localityRes.data.location;
  }
  else {
    locality = []
  }
  
  //location dropdown function
  const echronEvLocation = (id, url, type) => {
    if(type=='city'){
      setEchronSt({cityId: id, cityUrl: url, localityId: 0})
    }
    else if(type=='locality') {
      setEchronSt(prevState => ({
        ...prevState, // Maintain other properties
        localityId: id, // Update cityId
        localityUrl: url // Update cityUrl
      }));
    }
  }

  const navigate = useNavigate();

  //location search function
  const echronEvSearch = () => {
    if(echronSt.cityId) {
      let url
      url = echronSt.localityId? echronSt.localityUrl : echronSt.cityUrl
      navigate(url);
    }
    else {
      alert('Please Select City')
    }
  }
  
	if(citiesRes.isLoading) return <Loader />
	if(citiesRes.isError) return <h1>An Error occured {citiesRes.error.error}</h1>
	if(citiesRes.isSuccess) {
    return (
      <>
        <div className="EchronHomebanner">
          <div className="echron-main">
            <div className="banner-image">
              <img src={props.img2? props.img2 : Banner1} alt="" />
            </div>
            <div className="echron-content">
              <div className="heading">
                <h1 className="h1">Find Your Property</h1>
                <h2 className="h2">Discover the best real-estate properties in India</h2>
              </div>
              {/* <div className="form">
                <input
                  className="input"
                  type="text"
                  placeholder="Search By City, Location, Project Developer"
                ></input>
                <button className="btn">
                  <i className="fa fa-search"></i> Search
                </button>
              </div> */}
              
              <div className="custom-select">
                <select onChange={(e) => echronEvLocation(e.target.value, e.target.selectedOptions[0].getAttribute('data-url'), 'city')}>
                  <option value="0">Select City:</option>
                  {
                    citiesRes.data.location.map((city)=>
                      <option data-url={city.url} value={city.id} key={city.id}>{city.name}</option>
                    )
                  }
                </select>
                <select onChange={(e) => echronEvLocation(e.target.value, e.target.selectedOptions[0].getAttribute('data-url'), 'locality')}>
                  <option value="0">Select Locality:</option>
                  {
                    locality.map((locality)=>
                      <option data-url={locality.url} value={locality.id} key={locality.id}>{locality.name}</option>
                    )
                  }
                </select>
                <button className="btn" onClick={() => echronEvSearch()}>
                  <i className="fa fa-search"></i> 
                </button>
              </div>
              <div className="key"><a href="../../commercial-properties" >  Discover Commercial Projects</a></div>
              <div className="list">
                <ul>
                  <li>
                    <div className="span">42</div>
                    <div className="span">Cities</div>
                  </li>
                  <li>
                    <div className="span">5892</div>
                    <div className="span">Projects</div>
                  </li>
                  <li>
                    <div className="span">680</div>
                    <div className="span">Developer</div>
                  </li>
                  <li>
                    <div className="span">56798</div>
                    <div className="span">Costomers</div>
                  </li>
                  <li>
                    <div className="span">30500Cr</div>
                    <div className="span">Worth Property Sold</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Banner;
